<template>
    <label :class="labelClasses">
        {{ label }}
        <input
            class="ui-checkbox-input"
            type="checkbox"
            :id="id"
            :name="name"
            :value="value"
            :disabled="disabled || readonly"
            @change="handleChange"
        />
        <span class="ui-checkbox-icon"></span>
    </label>
</template>

<script setup>
    import { computed, toRefs } from "vue";

    // TODO: убрать ненужные пропсы и классы стилей
    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        labelClass: {
            type: String,
            default: null,
        },
        for: {
            type: String,
            default: null,
        },
        id: {
            type: String,
            default: "ui-checkbox",
        },
        rightFloat: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: "md",
        },
        name: {
            type: String,
            default: "",
        },
        value: {
            type: String,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
        values: {
            type: Array,
            default: () => [],
        },
        xModel: {
            type: String,
            default: null,
        },
        wireChange: {
            type: String,
            default: null,
        },
        wireModel: {
            type: String,
            default: null,
        },
    });

    const emit = defineEmits(["update:modelValue"]);

    const { checked, values, value } = toRefs(props);

    const labelClasses = computed(() => [
        `ui-checkbox size-${props.size}`,
        {
            disabled: props.disabled || props.readonly,
            "right-float": props.rightFloat,
            "without-label": !props.label,
            [props.labelClass]: !!props.labelClass,
        },
    ]);

    function handleChange(event) {
        emit("update:modelValue", event.target.value);
    }
</script>
